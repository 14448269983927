.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-fixed {
  margin-top: 6rem;
}


table {
  border: 1px solid #0dcaf0;
}

table tr {
  border: 1px solid #0dcaf0;
}

table tr th, table tr td {
  border: 1px solid #0dcaf0;
  padding: 5px 15px;
  font-size: 16px;
  color: #0dcaf0;
}

table tr td {
  color: #000;
}

table .children {
  padding-left: 40px;
}

table .group td {
  font-style: italic;
}

select {
  margin-left: 5px;
  min-width: 200px;
  height: 40px;
  padding: 5px;
}

.react-datepicker select {
  height: unset;
  padding: inherit;
  min-width: 0;
  margin-left: 0;
}

a {
  cursor: pointer;
  text-decoration: underline !important;
}

input[type="text"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

textarea {
  width: 100%;
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 5px;
}

input[type="number"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="email"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="password"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="checkbox"] {
  margin-left: 8px;
}

.clickable {
  cursor: pointer;
}

.selected {
  background: #ffc107;
}

.customers-container {
  background: #eee;
  padding: 20px;
  width: 100%;
}

.customers-dnd-item-container {
  border: 1px dotted #777;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: move;
}

.customer-dnd-img {
  width:18px;
  height:18px;
  margin-right: 20px;
}

.customer-dnd-item-content {
  padding-left: 20px;
  font-size: 11px;
}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
}

.customer-delete-btn {
  margin-left: auto;
}

.react-datepicker__input-container input {
  height: 35px;
  width: 100%;
}

.admin-nav a {
  text-decoration: none !important;
}

.login {
  text-align: center;
  margin: auto;
}

.login-container {
  background: #eee;
  padding: 40px 5px;
}

.login-container input {
  min-width: 300px;
}

.btn-login {
  min-width: 300px;
}

.create-route-container {
  background: #eee;
  padding: 15px;
}

.logo {
  position: absolute;
  top: 30px;
  left: 40px;
  display: flex;
}

.logo img {
  height: 50px;
}

.customers-pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
  margin-top: 25px;
}

.customers-pagination li a {
  border-radius: 7px;
  padding: 0.1rem 0.8rem;
  border: none;
  cursor: pointer;
  background: transparent;
  color: #777;
}

.customers-pagination li {
  border: none;
}

.customers-pagination li a {
  border: none;
  text-decoration: none !important;
}

.customers-pagination li.previous a,
.customers-pagination li.next a,
.customers-pagination li.break a {
  border-color: transparent;
  background: transparent;
}

.personnel-info-table-container {
  overflow: auto;
}

.customers-pagination li.active a {
  background-color: #0366d6 !important;
  border-color: transparent;
  color: white;
  min-width: 32px;
}

.customers-pagination li.disabled a {
  color: grey;
  background: transparent !important;
}

.customers-pagination li.disable,
.customers-pagination li.disabled a {
  cursor: default;
  background: transparent;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 768px) {
  .landing {
    color: black;
  }
}

@media only screen and (min-width: 2000px) {
  .container {
    max-width: 1920px;
  }
}

