body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.client-bg {
  margin-top: 0 !important;
  max-width: inherit !important;
  background-color: #ffd9b0 !important;
}

/* @extend display-flex; */
.client-page display-flex, .booking-content {
  display: flex;
  display: -webkit-flex; }

/* @extend list-type-ulli; */
.client-page list-type-ulli, ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

@font-face {
  font-family: 'Permanent Marker';
  src: url("./fonts/PermanentMarker-Regular.ttf");
}
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/poppins/poppins-v5-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Light"), local("Poppins-Light"), url("./fonts/poppins/poppins-v5-latin-300.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-300.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-300.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-300.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-300.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url("./fonts/poppins/poppins-v5-latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Light Italic"), local("Poppins-LightItalic"), url("./fonts/poppins/poppins-v5-latin-300italic.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-300italic.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-300italic.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-300italic.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-300italic.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/poppins/poppins-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Regular"), local("Poppins-Regular"), url("./fonts/poppins/poppins-v5-latin-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-regular.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-regular.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-regular.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/poppins/poppins-v5-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Italic"), local("Poppins-Italic"), url("./fonts/poppins/poppins-v5-latin-italic.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-italic.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-italic.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-italic.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-italic.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/poppins/poppins-v5-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Medium"), local("Poppins-Medium"), url("./fonts/poppins/poppins-v5-latin-500.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-500.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-500.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-500.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-500.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/poppins/poppins-v5-latin-500italic.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"), url("./fonts/poppins/poppins-v5-latin-500italic.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-500italic.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-500italic.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-500italic.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-500italic.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/poppins/poppins-v5-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("./fonts/poppins/poppins-v5-latin-600.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-600.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-600.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-600.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-600.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/poppins/poppins-v5-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Bold"), local("Poppins-Bold"), url("./fonts/poppins/poppins-v5-latin-700.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-700.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-700.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-700.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/poppins/poppins-v5-latin-700italic.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"), url("./fonts/poppins/poppins-v5-latin-700italic.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-700italic.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-700italic.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-700italic.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-700italic.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/poppins/poppins-v5-latin-800.eot");
  /* IE9 Compat Modes */
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"), url("./fonts/poppins/poppins-v5-latin-800.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-800.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-800.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-800.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-800.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-800italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url("./fonts/poppins/poppins-v5-latin-800italic.eot");
  /* IE9 Compat Modes */
  src: local("Poppins ExtraBold Italic"), local("Poppins-ExtraBoldItalic"), url("./fonts/poppins/poppins-v5-latin-800italic.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-800italic.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-800italic.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-800italic.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-800italic.svg#Poppins") format("svg");
  /* Legacy iOS */ }
/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/poppins/poppins-v5-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Black"), local("Poppins-Black"), url("./fonts/poppins/poppins-v5-latin-900.eot?#iefix") format("embedded-opentype"), url("./fonts/poppins/poppins-v5-latin-900.woff2") format("woff2"), url("./fonts/poppins/poppins-v5-latin-900.woff") format("woff"), url("./fonts/poppins/poppins-v5-latin-900.ttf") format("truetype"), url("./fonts/poppins/poppins-v5-latin-900.svg#Poppins") format("svg");
  /* Legacy iOS */ }
.client-page  a:focus, .client-page a:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s; }

/* .client-page select, */
.client-page input, .client-page textarea {
  outline: none;
  appearance: unset !important;
  -moz-appearance: unset !important;
  -webkit-appearance: unset !important;
  -o-appearance: unset !important;
  -ms-appearance: unset !important; }

/*.client-page .react-datepicker select {
  outline: inherit;
  appearance: inherit !important;
  -moz-appearance: inherit !important;
  -webkit-appearance: inherit !important;
  -o-appearance: inherit !important;
  -ms-appearance: inherit !important; } */


.client-page input::-webkit-outer-spin-button, .client-page input::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  margin: 0; }

  /* .client-page select:focus, */
.client-page input:focus, .client-page textarea:focus {
  outline: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important; }

/* .client-page .react-datepicker select:focus {
  outline: inherit;
  box-shadow: inherit !important;
  -moz-box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
  -o-box-shadow: inherit !important;
  -ms-box-shadow: inherit !important;
} */

.client-page input[type=checkbox] {
  appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  -ms-appearance: checkbox !important; }

.client-page input[type=radio] {
  appearance: radio !important;
  -moz-appearance: radio !important;
  -webkit-appearance: radio !important;
  -o-appearance: radio !important;
  -ms-appearance: radio !important; }

.client-page img {
  max-width: 100%;
  height: auto; }

.client-page figure {
  margin: 0; }

.client-page p {
  margin-bottom: 0px; font-size: 18px; }

.client-page h2 {
  line-height: 1.8;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #fff;
  font-family: Poppins;
  font-size: 21px;
  text-align: center;
  margin-bottom: 28px; }

.client-page .clear {
  clear: both; }

.client-page body {
  font-size: 18px;
  line-height: 1.8;
  color: #fff;
  background: #ffd9b0;
  font-weight: 400;
  font-family: Poppins;
  margin: 0px; }

.client-page .main {
  padding: 115px 0;
  position: relative; }

.client-page .container {
  width: 1150px;
  background: #fff;
  margin: 0 auto;
  box-shadow: 0px 10px 9.9px 0.1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 10px 9.9px 0.1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 10px 9.9px 0.1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 10px 9.9px 0.1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 10px 9.9px 0.1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  padding-left: 0;
  padding-right: 0;
}

.client-page .booking-content {
  flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap; }

.client-page .booking-image {
  width: 58.33%;
  margin-bottom: -8px; }

.client-page .booking-img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; }

.client-page .hello {
  color: #FA08D5;
  text-align: center;
  font-family: 'Permanent Marker', Impact, Charcoal, sans-serif;
}

.client-page .booking-form {
  width: 41.67%;
  background: #111;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

.client-page .title-only {
  padding: 72px 70px 70px 70px;
}

.client-page .aos-title, .client-page .checkbox-title {
  color: #0df2f5;
  font-size: 18px;
  top: 1px;
}

.client-page .avatar-container {
  text-align: center;
  margin-bottom: 24px;
}

.client-page .avatar-container img {
  width: 100px;
  height: 100px;
}

.client-page .checkbox-widget {
  width: inherit;
  margin-left: 0;
  margin-top: 10px;
}

.client-page .aos-content {
  border: 1px solid white;
  font-size: 18px;
  color: white;
  padding: 10px;
  margin-bottom: 15px;
  height: 160px;
  overflow-y: auto;
}

.client-page .aos-content h6 {
  text-align: center;
}

.client-page .aos-content ul {
  z-index: 1;
  border-bottom: none;
  list-style-type: inherit;
  padding-left: 15px;
}

.client-page .aos-content ul li {
  color: white !important;
  display: list-item !important;
  padding: 0 !important;
  background: none !important;
  font-size: 18px !important;
}

.client-page .form-submit {
  margin-top: 50px;
}

.client-page .alert-container {
  margin-top: 25px;
}

.client-page .form-no-padding {
  padding-top:0 !important;
}

.client-page .form-radio {
  margin-bottom: 65px;
  padding-top: 20px; }
.client-page .form-radio input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px; }
  .client-page .form-radio label {
    padding-top: 4px; }
  .client-page .form-radio input + label {
    margin: 0 3px;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: solid 2px #ebebeb;
    background-color: #FFF;
    font-size: 18px;
    font-weight: 600;
    color: #888;
    text-align: center;
    transition: border-color .15s ease-out,  color .25s ease-out,  background-color .15s ease-out, box-shadow .15s ease-out;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%; }
  .client-page .form-radio input:checked + label {
    background-color: #111;
    color: #FFF;
    border-color: #111;
    z-index: 1; }
  .client-page .form-radio input:focus + label {
    outline: none; }
  .client-page .form-radio input:hover {
    background-color: #111;
    color: #FFF;
    border-color: #111; }
  .client-page input:disabled {
    border-bottom: none;
  }

.client-page .radio-item-list {
  margin: 0 -3px; }

.client-page .active {
  position: relative;
  background: #0df2f5;
  padding: 14px 1px;
  margin-right: 7px;
  margin-left: 3px;
  border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  -o-border-radius: 24px;
  -ms-border-radius: 24px; }
  .client-page .active:after {
    position: absolute;
    content: 'Person';
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    font-size: 18px;
    color: #222;
    font-weight: bold; }
  .client-page .active input + label {
    margin-right: 59px; }

.client-page #booking-form {
  padding: 72px 70px 70px 70px; }

.client-page .form-group {
  margin-bottom: 10px;
  position: relative; }

.client-page .form-input {
  padding-top: 21px; }

.client-page .form-label {
  position: absolute;
  transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -webkit-transition: 0.25s ease;
  -o-transition: 0.25s ease;
  -ms-transition: 0.25s ease;
  color: #bebebe;
  left: 0;
  top: 23px; }

.client-page input {
  transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  padding-top: 10px;
  -webkit-appearance: none; }
  .client-page input:focus + .form-label, input:valid + .form-label, .form-label-date {
    top: 1px;
    font-size: 18px;
    color: #0df2f5; }
    /* .client-page select */

  .client-page .form-label-date-blurb {
    font-size: 14px;
    color: #0df2f5;
  }

  .client-page .sent-to-email {
    color: white !important;
  }

.client-page input {
  box-sizing: border-box;
  width: 100%;
  display: block;
  border: none;
  font-family: Poppins;
  color: #fff;
  background: transparent;
  border-bottom: 1px solid #999;
  padding: 4px 0; }
  input:focus, select:focus {
    border-bottom: 1px solid #0df2f5; }

/* .client-page .react-datepicker select {
  box-sizing: initial;
  width: initial;
  display: initial;
  border: initial;
  font-family: initial;
  color: initial;
  background: initial;
  border-bottom: initial;
  padding: initial;
} */

/* .client-page .react-datepicker select:focus {
  border-bottom: initial;
} */

.client-page input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset; }

.client-page input::-webkit-input-placeholder {
  color: #bebebe; }
.client-page input::-moz-placeholder {
  color: #bebebe; }
.client-page input:-ms-input-placeholder {
  color: #bebebe; }
.client-page input:-moz-placeholder {
  color: #bebebe; }

.client-page .submit {
  width: auto;
  background: #0df2f5;
  color: #fff;
  padding: 15px 43px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  cursor: pointer; }
  .client-page .submit:hover {
    background: #e79e50; }

.client-page ul {
  background: 0 0;
  z-index: 9;
  border-bottom: 1px solid #999;
  padding-top: 15px; }

.client-page ul li {
  padding: 5px 0px;
  z-index: 2;
  color: #bebebe; }

.client-page ul li:not(.init) {
  display: none;
  background: #fff;
  color: #222;
  padding: 5px 10px; }

.client-page ul li:not(.init):hover,.client-page ul li.selected:not(.init) {
  background: #0df2f5; }

.client-page li.init {
  cursor: pointer;
  position: relative; }
  .client-page li.init:after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    font-size: 20px;
    color: #bebebe;
    font-family: 'Material-Design-Iconic-Font';
    content: '\f2f2'; }

.client-page .label-radio {
  display: block;
  margin-bottom: 18px; }

.client-page .vertify-booking {
  color: #0df2f5;
  font-size: 18px;
  text-decoration: none;
  margin-top: 14px;
  display: block;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s; }
  .client-page .vertify-booking:hover {
    text-decoration: underline; }

.client-page .select-list {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 47px; }


.client-page .list-item {
  position: absolute;
  width: 100%; }

.client-page #time {
  z-index: 99; }

.client-page #food {
  z-index: 9; }

@media screen and (max-width: 1200px) {
  .client-bg {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .client-page .main {
    padding: 0;
  }
  .client-page .container {
    margin-left: 0;
    margin-right: 0;
    max-width: inherit;
    width: 100%;
    background: transparent;
  }

  .client-page .booking-content {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column; }

  .client-page .booking-image, .client-page .booking-form {
    width: 100%; }

  .client-page .booking-image {
    order: 2;
    text-align: center; }

  .client-page .booking-form {
    order: 1;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }

  .client-page .booking-img {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%; } }
@media screen and (max-width: 480px) {
  .client-page .radio-item {
    display: block;
    margin-bottom: 15px;
    width: 150px; }

  .client-page .active {
    padding: 7px 1px; }

  .client-bg {
    background-color: #111 !important;
  }

  .client-page form {
    padding: 72px 40px 70px 40px; } }

@media screen and (min-width: 1200px) {
  .client-page .booking-form {
    height: 671px;
    overflow-y: auto;
  }
}

/*# sourceMappingURL=style.css.map */

